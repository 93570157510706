/** 文章详情 */
<template>
  <div class="details content">
    <div class="justify-between">
      <section class="details-left flex-1">
        <div class="details-main back-fff border-r8 mb-30">
          <h2 class="details-title">{{article.title}}</h2>
          <p class="details-record">
            <Share v-if="$isPc" />
            <span>{{article.read_num}}人浏览</span>
            <span>{{article.created_at|filterTime}}</span>
          </p>
          <el-divider></el-divider>
          <div class="details-content" v-html="article.content"> </div>
        </div>
        <!-- 上一篇、下一篇 -->
        <el-row :gutter="$isPc?20:0" class="details-prev-next">
          <el-col v-if="articlePrev && Object.keys(articlePrev).length" :span="12">
            <div class="details-prev" @click="changeQuery(articlePrev.id)">
              <p>
                <span>上一篇</span>
                <span>{{articlePrev.created_at|filterTime}}</span>
              </p>
              <h2 class="text-ellipsis-lines mtb-30">{{articlePrev.title}}</h2>
            </div>
          </el-col>
          <el-col v-if="articleNext && Object.keys(articleNext).length" :span="12">
            <div class="details-next" @click="changeQuery(articleNext.id)">
              <p>
                <span>下一篇</span>
                <span>{{articleNext.created_at|filterTime}}</span>
              </p>
              <h2 class="text-ellipsis-lines mtb-30">{{articleNext.title}}</h2>
            </div>
          </el-col>
        </el-row>
      </section>
      <!-- 更多相关文章 -->
      <section v-if="$isPc" class="details-right flex-1 ml-20">
        <div class="details-other back-fff border-r8">
          <h4 class="details-other-title">相关文章</h4>
          <ul class="details-other-list">
            <li v-for="(item,index) in articleOther" :key="index" class="details-other-item ">
              <p class="text-ellipsis ptb-20 " @click="changeQuery(item.id)">{{item.title}}</p>
            </li>
          </ul>
          <el-button type="text" class="details-other-btn" @click="gotoUrl(article.type)">查看更多</el-button>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { getArticle, getArticleinfo, addReading, setShare } from '@/api/index'
import { getItem } from '@/utils/storage'
import wx from 'weixin-js-sdk'
import Share from '@/components/Share.vue'
export default {
  name: 'Details',
  components: {
    Share
  },
  data () {
    return {
      article: {},//文章详情
      articlePrev: {},//上一篇
      articleNext: {},//下一篇
      articleOther: [],//相关文章
    }
  },
  mounted () {
    this.getArticleinfo()
  },
  methods: {
    // 获取文章详情
    async getArticleinfo () {
      try {
        let { data, prev, next } = await getArticleinfo(this.$route.query.id)
        this.article = data[0]
        this.articlePrev = prev[0]
        this.articleNext = next[0]
        this.$store.dispatch('setArticleType', '/' + this.article.type)
        this.setShare()
        await addReading(this.$route.query.id)
        this.getArticle()
      } catch (error) { }
    },
    // 获取相关文章
    async getArticle () {
      try {
        let { data } = await getArticle({ page: 1, page_size: 5, type: this.article.type })
        this.articleOther = data
      } catch (error) { }
    },
    // 分享
    async setShare () {
      let links = encodeURIComponent(window.location.href.split('#')[0])
      // ios系统需使用第一次进入网站时的地址
      if (getItem('isIOS')) {
        links = encodeURIComponent(getItem('firstUrl'))
      }
      let { data } = await setShare(links)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      });
      let options = {
        title: this.article.title, // 分享标题
        desc: this.$settleHtml(this.article.introduction), // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.$base + this.article.thumbnail, // 分享图标
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          ...options,
          success: function () {
            // alert('分享朋友成功')
          }
        })
        wx.updateTimelineShareData({
          ...options,
          success: function () {
            // alert('分享朋友圈成功')
          }
        })
      })
    },
    // 切换上一篇、下一篇、其他文章
    changeQuery (query) {
      if (this.$route.query.id != query) {
        this.$router.replace({ query: { id: query } })
        this.getArticleinfo()
      }
    },
    // 查看更多
    gotoUrl (type) {
      let url = '/'
      switch (type) {
        case 'alerts':
          url = '/market'
          break;
        case 'report':
          url = '/report'
          break;
        case 'policy':
          url = '/policy'
          break;
        default:
          break;
      }
      this.$router.push(url)
    }
  },
}
</script>
<style lang="scss" scoped>
.details-left {
  // 文章主体
  .details-main {
    padding: 40px 70px 70px;
    text-align: center;
    .details-title {
      font-size: 24px;
      line-height: 28px;
    }
    .details-record {
      margin-top: 43px;
      color: #666666;
      span {
        margin: 0 19px;
      }
    }
    .el-divider--horizontal {
      margin: 35px 0 57px;
    }
    .details-author {
      font-size: 14px;
      color: #666666;
      margin-bottom: 34px;
      span {
        margin: 0 24px;
      }
    }
    /deep/ .details-content {
      text-align: left;
      p,
      li,
      figure {
        line-height: 34px;
        word-break: break-word;
      }
      h4,
      h3 {
        font-size: 20px;
        margin: 40px 0 10px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      table {
        width: 100%;
      }
    }
  }
  // 上一篇、下一篇
  .details-prev-next {
    .el-col {
      div {
        min-height: 146px;
        border-radius: 6px;
        padding: 20px 30px;
        background-color: #ebebeb;
        cursor: pointer;
        p {
          font-size: 18px;
          color: #666666;
          line-height: 18px;
          span:last-child {
            float: right;
            font-size: 14px;
          }
        }
        h2 {
          font-size: 20px;
          line-height: 34px;
        }
      }
    }
  }
}

.details-right {
  max-width: 380px;
  width: 27.2%;
  // 相关文章
  .details-other {
    text-align: center;
    overflow: hidden;
    .details-other-title {
      text-align: center;
      background-color: #1e90fb;
      line-height: 62px;
      font-size: 18px;
      color: #fff;
    }
    .details-other-list {
      padding: 0 20px;
      .details-other-item {
        text-align: left;
        border-bottom: 1px solid #e8e8e8;
        &:last-child {
          border-bottom: none;
        }
        p {
          width: 100%;
          cursor: pointer;
          &:hover {
            color: #1e90fb;
          }
        }
      }
    }
    .details-other-btn {
      margin: 10px 0 30px;
      color: #1e90fb;
      &:hover {
        color: #4e9de7;
      }
    }
  }
}
@media (max-width: 767px) {
  .details-left {
    .details-main {
      padding: 10px;
      margin-bottom: 0;
      .details-title {
        font-size: 20px;
        line-height: 24px;
      }
      .details-record {
        margin-top: 25px;
      }
      .el-divider--horizontal {
        margin: 30px 0 25px;
      }
    }
    .details-prev-next {
      margin-bottom: 20px;
      padding: 0 5px;
      .el-col {
        padding: 0 5px;
        div {
          min-height: 78px;
          border-radius: 0;
          padding: 10px;
          background-color: #ebebeb;
          cursor: pointer;
          p {
            font-size: 14px;
            line-height: 18px;
          }
          h2 {
            font-size: 16px;
            line-height: 20px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>